section#contact{

    a{
        color: $color-accent;
    }

    @media (min-width: 992px){
        background-image: url(../img/bg-end.svg);
        background-position: bottom left;
        background-repeat: no-repeat;
        background-size: contain;
    }

    form{
        label{
            height: 50px;
            font-size: 14px;
            font-weight: 800;
            line-height: 3.57;
            letter-spacing: 1.7px;
            text-transform: uppercase;
            color: $color-text-primary;
            cursor: pointer;
        }

        .form-control{
            border-radius: 4px;
            background-color: $color-form-control-bg;
            border: none;
            padding: 1rem;

            &:hover{
                background-color: lighten($color-form-control-bg, 2%);
            }
            &:focus{
                box-shadow: none;
                background-color: darken($color-form-control-bg, 5%);
            }
        }

        .btn-contact{
            font-size: 14px;
            font-weight: 800;
            line-height: 2.45;
            letter-spacing: 1.7px;
            color: white;
            min-width: 200px;
            max-width: 100%;
            border-radius: 4px;
            background-image: linear-gradient(122deg, #9800d4, #ff214d);
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2),
                        inset 0 0px 0px 2px rgba(200, 20, 140, 0),
                        inset 0 0 0 30px rgba(255, 255, 255, 0);
            border: none;
            padding: 8px 24px;
            margin-top: 1rem;
            text-transform: uppercase;
            cursor: pointer;
            transition: all .1s;

            &:disabled{
                opacity: 0.5;
            }

            &:hover, &:focus, &:disabled{
                color: rgb(200, 20, 140);
                background-color: white;
                box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2),
                            inset 0 0px 0px 2px rgba(200, 20, 140, 1),
                            inset 0 0 0 30px rgba(255, 255, 255, 1);

                &:active{
                    color: rgb(200, 20, 140);
                    background-color: white;
                    box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2),
                                inset 0 0px 0px 2px rgba(200, 20, 140, 1),
                                inset 0 0 0 30px rgba(255, 255, 255, 1);
                }
            }

            &:focus{
                outline: none;
            }
        }
    }
}
