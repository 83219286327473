.header-placeholder{
    height: 175px;

    @media (max-width: 1000px){
        height: 83px;
    }
}

header{
    transition-property: background-color, box-shadow, margin-top;
    padding: 70px 0 40px 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &.headroom--not-top{
        position: fixed;
        background: rgba(255, 255, 255, .9);
        z-index: 100;
        padding: .5rem 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    }

    @media (max-width: 1000px){
        transition-duration: .5s;
        position: fixed;
        background: rgba(255, 255, 255, .9);
        z-index: 100;
        padding: .5rem 0;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);

        @media (orientation: landscape) {
            &.headroom--unpinned.headroom--not-top{
                margin-top: -83px;
                box-shadow: 0 0 5px 0 transparent;
            }
        }
    }

}

nav{
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2.4px;

    .navbar-brand{
        img.desktop{
            width: 190px;
            height: 41px;
        }
        img.mobile{
            margin-left: .7rem;
            width: 41px;
            height: 41px;
        }
    }

    .navbar-toggler{
        top: .5rem;
        padding: .25rem;
        border: none;
    }

    .navbar-collapse{

        .nav-item{
            margin: 0 1rem;

            @media (max-width: 991px){
                margin: .3rem 0;
            }

            .with-underline{
                width: calc(100% - 1rem);
                opacity: 1;

                @media (max-width: 991px){
                    width: 100%;
                }
            }

            @media (min-width: 992px){
                &:first-child{
                    margin-left: 0;
                }

                &:last-child{
                    margin-right: 0;
                }
            }

            &.active{
                .nav-link{
                    color: $color-accent;
                    &:before{
                        @extend .with-underline;
                    }
                }
            }

            .nav-link{
                color: $color-text-primary;
                position: relative;
                transition: all .4s cubic-bezier(0, 1, 1, 1);

                &:hover{
                    color: $color-accent;
                    &:before{
                        @extend .with-underline;
                    }
                }

                &:before{
                    content: "";
                    position: absolute;
                    bottom: .1rem;
                    left: 50%;
                    width: 0%;
                    opacity: 0;
                    transform: translateX(-50%);
                    height: 2px;
                    background-color: $color-accent;
                    transition: all .4s cubic-bezier(0, 1, 1, 1);
                }

                &.active{
                    color: $color-accent;
                    &:before{
                        @extend .with-underline;
                    }
                }
            }
        }
    }
}
